import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Box } from '@mui/material';

import { AppContext } from '../../../../store';
import { loadAdministrators } from '../../../../domain/administrator/actions';
import { filterAdministrators, resetAdministratorsFilter } from '../../../../domain/administrator/actions/filter';
import Loader from '../../../../components/shared/loader';
import { ErrorNotification } from '../../../../components/shared/error';

import { Administrator } from './administrator';
import { AdministratorState } from '../../../../domain/administrator/reducer';
import { PageTitle } from '../../../../components/shared/page-title';
import { ScrollToTop } from '../../../../components/shared/scroll-to-top';
import { GoBackButton } from '../../../../components/shared/go-back';
import { DocumentState } from '../../../../domain/document/reducer';
import { Notification } from '../../../../components/shared/notification';
import { GenericBoxItem } from '../../../../components/shared/card-box-container';

import { Filters } from '../../../../components/shared/filters';

const renderResults = (
    adminState: AdministratorState,
    documentState: DocumentState,
    resetFilters: Function,
    handleFilterChange: React.ChangeEventHandler,
    setAdmFilterValue: Function,
    admFilterValue: string
) =>
    Object.keys(adminState.all).length > 0 ? (
        <>
            <Filters
                filterValue={admFilterValue}
                handleFilterChange={handleFilterChange}
                resetFilters={resetFilters}
                setFilterValue={setAdmFilterValue}
                entityState={adminState}
            />

            {adminState.filterResults.map((id) => (
                <Administrator
                    key={`adm-${id}`}
                    admin={adminState.all[id]}
                    documentState={documentState}
                    administratorState={adminState}
                />
            ))}
        </>
    ) : (
        <Notification warn title="No hay resultados" msg="No se encontraron resultados" />
    );

export const AdministratorList = ({ history }: RouteComponentProps) => {
    const { administrator: administratorState, document: documentState } = useContext(AppContext);
    useEffect(() => {
        console.log('useEffect HOOK! admins');
        const adminsLoaded = Object.keys(administratorState.all).length > 0;
        if (!adminsLoaded) {
            loadAdministrators()(administratorState.dispatch);
        }
    }, []);

    const [admFilterValue, setAdmFilterValue] = useState('');
    const handleFilterChange = (e: any) => {
        setAdmFilterValue(e.target.value);
        filterAdministrators({ text: e.target.value })(administratorState.dispatch);
    };
    const resetFilters = () => {
        setAdmFilterValue('');
        resetAdministratorsFilter()(administratorState.dispatch);
    };

    return (
        <>
            <PageTitle title={'Listado de Administraciones'}></PageTitle>

            <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1fr, 2fr, 1fr)' }}>
                <GenericBoxItem>
                    <h3>Listado de Administraciones</h3>
                </GenericBoxItem>

                {administratorState.loading && (
                    <GenericBoxItem>
                        <Loader />
                    </GenericBoxItem>
                )}
                {administratorState.error && (
                    <GenericBoxItem>
                        <ErrorNotification error={administratorState.error} />
                    </GenericBoxItem>
                )}

                {!administratorState.error && !administratorState.loading && (
                    <GenericBoxItem>
                        {renderResults(
                            administratorState,
                            documentState,
                            resetFilters,
                            handleFilterChange,
                            setAdmFilterValue,
                            admFilterValue
                        )}
                    </GenericBoxItem>
                )}

                <GenericBoxItem>
                    <GoBackButton history={history} />
                </GenericBoxItem>
            </Box>
            <ScrollToTop />
        </>
    );
};
