import { where, filter, curry, anyPass } from 'ramda';

import { ProductState } from '.';
import { FilterRequest } from '../actions/index.d';

export const filterProducts = (request: FilterRequest, products: ProductState['all']) => {
    const containsInsensitive = curry(
        (a, b) =>
            typeof a !== 'undefined' &&
            typeof b !== 'undefined' &&
            String(b).toLowerCase().indexOf(String(a).toLowerCase()) > -1
    );
    const matchDescField = where({
        desc: containsInsensitive(request.text),
    });
    const matchDescShortField = where({
        descShort: containsInsensitive(request.text),
    });

    const allConditions = anyPass([matchDescField, matchDescShortField]);

    return filter(allConditions, products);
};