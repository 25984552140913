import React, { useEffect, useState } from 'react';

import { Alert } from '@mui/material';
import { getClientsByAdministrator } from '../../../../domain/administrator/actions/searchClientsByAdministrator';
import Loader from '../../../../components/shared/loader';
import { Link } from 'react-router-dom';
import { getStatusDescription } from '../../../../domain/status/selectors';

export const AdministratorConsorcios: React.FunctionComponent<{ adminId: number }> = ({ adminId }) => {
    const [state, setState] = useState({
        isLoading: true,
        hasError: false,
        errorMessage: '',
        data: [] as administrator.SearchClientsByAdministratorUIModel[],
    });

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const results = await getClientsByAdministrator({
                    adminId,
                });
                setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    hasError: false,
                    data: results,
                }));
            } catch (e) {
                setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    hasError: true,
                    data: [],
                }));
            }
        };

        fetchClients();
    }, [adminId]);

    if (state.isLoading) return <Loader />;

    if (state.hasError) return <Alert severity="error">Se ha producido un error!</Alert>;

    if (state.data.length === 0) return <Alert severity="info">No hay consorcios asociados!</Alert>;

    return (
        <>
            {state.data.map(({ id, name, address, status }) => (
                <div key={`adm-${adminId}-cli-${id}`}>
                    <Link to={`/client/details/${id}`}>
                        [{id}] {name} - {address.street} N&deg; {address.door}
                    </Link>
                    {status === 2 && (
                        <>
                            &nbsp;- <span className="text-danger">{getStatusDescription(status)}</span>
                        </>
                    )}
                </div>
            ))}
        </>
    );
};
