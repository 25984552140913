import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { AppContext } from '../../../store';

import { ClientDetailsProps } from '../../../domain/client/client';
import { getIndustryDescription } from '../../../domain/industry/selectors';
import { getDocumentDescription } from '../../../domain/document/selectors';
import { ClientAddress } from './client-address';
import { ClientPhoneNumbers } from './client-phone-numbers';
import { formatDocument, isInactive } from '../../../utils/client.utils';
import { LongMenu } from '../../../components/shared/menu-max-height';
import { Alert, Box } from '@mui/material';
import { CardBoxContainer, GenericBoxItem } from '../../../components/shared/card-box-container';

export const ClientSearchResult: React.FunctionComponent<ClientDetailsProps> = ({ client }) => {
    const { industry: industryState, document: documentState, status: clientStatus } = useContext(AppContext);
    const history = useHistory();

    const cardClass = classnames({
        'border-danger': client.status === 2,
    });
    const stateClass = classnames({
        'text-danger': client.status === 2,
    });
    const renderMissingItems = (client: backend.client.GetClientDetailsResponseSuccess) => (
        <div className="card-text">
            {!client.document && <Alert severity="warning">No tiene documento registrado!</Alert>}
            {!client.email && <Alert severity="info">No tiene email registrado!</Alert>}
        </div>
    );
    const { tel1_area, tel1_num, tel2_area, tel2_num } = client;
    const phones = { tel1_area, tel1_num, tel2_area, tel2_num };
    const isClientInactive = isInactive(client);
    return (
        <CardBoxContainer className={cardClass}>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '10fr 1fr',
                    bgcolor: 'primary.light',
                    color: 'primary.contrastText',
                    fontWeight: 'bold',
                }}
                className={classnames({
                    'inactive_item': isClientInactive,
                })}
            >
                <GenericBoxItem className="text-uppercase mr-0 pr-0">
                    [{client.id}] {client.name}
                    {client.fantasyName && <div className="fantasy">({client.fantasyName})</div>}
                </GenericBoxItem>
                <GenericBoxItem className="ml-0 pl-0 text-right">
                    <LongMenu
                        options={[
                            {
                                label: 'Detalles',
                                onClick: () => history.push(`/client/details/${client.id}`),
                            },
                            {
                                label: 'Asociar Extintores',
                                onClick: () =>
                                    history.push(`/client/${client.id}/extinguishers/link`, { client: client }),
                                disabled: isClientInactive,
                            },
                            {
                                label: 'Presupuestar',
                                onClick: () => history.push(`/client/${client.id}/quote`, { client: client }),
                                disabled: isClientInactive,
                            },
                            {
                                label: 'Ver Presupuestos',
                                onClick: () => history.push(`/client/${client.id}/quotes`, { client: client }),
                            },
                            {
                                label: 'Modificar',
                                onClick: () => history.push(`/client/${client.id}/edit`, { client }),
                            },
                        ]}
                    />
                </GenericBoxItem>
            </Box>
            <div
                className={classnames('card-body', {
                    'inactive_item': isClientInactive,
                })}
            >
                {renderMissingItems(client)}
                {client.auditoria && <div className="card-text text-warning">CON AUDITORIA</div>}
                {client.document && (
                    <div className="card-text">
                        <p className="document">
                            {getDocumentDescription(client.documentType, documentState) +
                                ' ' +
                                formatDocument(client.document)}
                        </p>
                    </div>
                )}

                <div className="card-text">
                    <ClientPhoneNumbers {...phones} />
                </div>
                <div className="card-text">Contacto: {client.contact}</div>
                <ClientAddress address={client.address1} />
                <div className="card-text">
                    <div className="row">
                        <div className="card-text col-6">{getIndustryDescription(client.industry, industryState)}</div>
                        <div className={`card-text col-6 text-right ${stateClass}`}>{clientStatus[client.status]}</div>
                    </div>
                </div>
            </div>
        </CardBoxContainer>
    );
};
