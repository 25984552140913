import React, { useContext } from "react";

import { AppContext } from "../../store";
import ConfigGenericContainer  from "../../components/shared/config-item/config-generic-container";

export const ProductContainer = () => {
  const { product: productState } = useContext(AppContext);

  return (
    <ConfigGenericContainer
      configItemSpecificState={productState}
    ></ConfigGenericContainer>
  );
};
