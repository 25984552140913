import * as R from 'ramda';
import { ProductAction, ProductActionType } from "../actions/types";
import { filterProducts } from "./utils";

export interface ProductState {
  all: Array<product.UIModel>;
  loading: boolean;

  error?: backend.common.ErrorResponse;
  dispatch?: any;
  filterResults: number[];
}
export default (state: ProductState, action: ProductAction): ProductState => {
  switch (action.type) {
    case ProductActionType.LOAD_SUCCESS: {
      return {
        ...state,
        all: action.payload,
        filterResults: action.payload.sort(sortElementsByName).map((el) => el.id),
        loading: false,
        error: undefined
      };
    }
    case ProductActionType.CREATE_SUCCESS:
    case ProductActionType.EDIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: undefined
      };
    }

    case ProductActionType.DO_FILTER: {
      const filteredItems = R.isEmpty(action.payload)
          ? state.all
          : filterProducts(action.payload, state.all);
      return {
          ...state,
          filterResults: Object.values(filteredItems)
              .sort(sortElementsByName)
              .map((el) => el.id),
      };
  }
  case ProductActionType.RESET_FILTER: {
      return {
          ...state,
          filterResults: Object.values(state.all)
              .sort(sortElementsByName)
              .map((el) => el.id),
          error: undefined,
      };
  }

    case ProductActionType.LOAD_INIT:
    case ProductActionType.CREATE_INIT:
    case ProductActionType.EDIT_INIT:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case ProductActionType.LOAD_FAILURE:
    case ProductActionType.CREATE_FAILURE:
    case ProductActionType.EDIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return { ...state };
  }
};

const sortElementsByName = (a: product.UIModel, b: product.UIModel) =>
    a.descShort.toUpperCase() < b.descShort.toUpperCase() ? -1 : 1;
