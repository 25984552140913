import React from 'react';

import { Box, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { GenericBoxItem } from '../card-box-container';
import { Notification } from '../notification';
import { ClientState } from '../../../domain/client/reducer/client';

import { AdministratorState } from '../../../domain/administrator/reducer';
import { ProductState } from '../../../domain/product/reducer';

interface FiltersProps {
    filterValue: string;
    handleFilterChange: React.ChangeEventHandler;
    resetFilters: Function;
    setFilterValue: Function;
    entityState: ClientState | AdministratorState | ProductState;
}
export const Filters: React.FC<FiltersProps> = ({
    filterValue,
    handleFilterChange,
    resetFilters,
    setFilterValue,
    entityState,
}) => {
    const isClientSearch = (entityState as ClientState).searchResults !== undefined;
    const entities = isClientSearch
        ? (entityState as ClientState).searchResults
        : (entityState as AdministratorState).all;
    const numberOfFilterElements = entityState.filterResults.length;
    return (
        <>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '6fr 4fr',
                }}
            >
                <GenericBoxItem>
                    <TextField
                        label="Filtros"
                        name="filters"
                        value={filterValue}
                        onChange={handleFilterChange}
                        InputProps={{
                            endAdornment: (
                                <ClearIcon
                                    onClick={() => {
                                        resetFilters();
                                        setFilterValue('');
                                    }}
                                    sx={{ '&:hover': { cursor: 'pointer' } }}
                                />
                            ),
                        }}
                        variant="standard"
                        autoComplete="off"
                        autoFocus
                        fullWidth
                    />
                </GenericBoxItem>

                <GenericBoxItem className="d-flex align-items-center">
                    <label htmlFor="filters" className="m-0">
                        (mostrando {numberOfFilterElements} de {Object.keys(entities).length} "resultados")
                    </label>
                </GenericBoxItem>
            </Box>
            {numberOfFilterElements === 0 && (
                <Notification
                    warn
                    title="No se encontraron resultados"
                    msg="No hay resultados que concuerden con los filtros. Modifique el valor ingresado como filtro e intente nuevamente"
                />
            )}
        </>
    );
};
