import { FilterRequest } from './index.d';

export enum ProductActionType {
    LOAD_INIT = '@product/LOAD_INIT',
    LOAD_SUCCESS = '@product/LOAD_SUCCESS',
    LOAD_FAILURE = '@product/LOAD_FAILURE',

    CREATE_INIT = '@product/CREATE_INIT',
    CREATE_SUCCESS = '@product/CREATE_SUCCESS',
    CREATE_FAILURE = '@product/CREATE_FAILURE',

    EDIT_INIT = '@product/EDIT_INIT',
    EDIT_SUCCESS = '@product/EDIT_SUCCESS',
    EDIT_FAILURE = '@product/EDIT_FAILURE',

    RESET_FILTER = '@product/RESET_FILTER',
    DO_FILTER = '@product/DO_FILTER',
}

export type ProductAction =
    | { type: ProductActionType.LOAD_INIT }
    | {
          type: ProductActionType.LOAD_SUCCESS;
          payload: product.UIModel[];
      }
    | {
          type: ProductActionType.LOAD_FAILURE;
          payload: backend.common.ErrorResponse;
      }
    | { type: ProductActionType.CREATE_INIT }
    | {
          type: ProductActionType.CREATE_SUCCESS;
      }
    | {
          type: ProductActionType.CREATE_FAILURE;
          payload: backend.common.ErrorResponse;
      }
    | { type: ProductActionType.EDIT_INIT }
    | {
          type: ProductActionType.EDIT_SUCCESS;
      }
    | {
          type: ProductActionType.EDIT_FAILURE;
          payload: backend.common.ErrorResponse;
      }
    | { type: ProductActionType.DO_FILTER; payload: FilterRequest }
    | { type: ProductActionType.RESET_FILTER };
