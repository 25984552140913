import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { ClientAddress } from '../../../client-search/components/client-address';
import { ClientPhoneNumbers } from '../../../client-search/components/client-phone-numbers';
import { ExpandPanel } from '../../../../components/shared/expand-panel';
import { formatDocument } from '../../../../utils/client.utils';
import { isInactive } from '../../../../utils/adm.utils';
import { DocumentState } from '../../../../domain/document/reducer';
import { getDocumentDescription } from '../../../../domain/document/selectors';
import { LongMenu } from '../../../../components/shared/menu-max-height';
import { AlertDialogWrapper } from '../../../../components/shared/alert-dialog-wrapper';
import { remove } from '../../../../domain/administrator/actions/delete';
import { AdministratorState } from '../../../../domain/administrator/reducer';
import { CardBoxContainer, GenericBoxItem } from '../../../../components/shared/card-box-container';
import { getClientsByAdministrator } from '../../../../domain/administrator/actions/searchClientsByAdministrator';
import { AdministratorConsorcios } from './administratorConsorcios';

interface AdministratorComponentProps {
    admin: administrator.UIModel;
    documentState: DocumentState;
    administratorState: AdministratorState;
}

const renderMissingItems = (admin: administrator.UIModel) => (
    <div className="card-text">
        {!admin.document && <Alert severity="warning">No tiene documento registrado!</Alert>}
        {typeof admin.email1 === 'undefined' && typeof admin.email2 === 'undefined' && (
            <Alert severity="info">No tiene email registrado!</Alert>
        )}
    </div>
);

export const Administrator: React.FunctionComponent<AdministratorComponentProps> = ({
    admin,
    documentState,
    administratorState,
}) => {
    const isInactiveAdm = isInactive(admin);
    const cardClass = classnames({
        'border-danger': isInactiveAdm,
    });

    const history = useHistory();
    const { tel1_area, tel1_num, tel2_area, tel2_num } = admin;
    const phones = { tel1_area, tel1_num, tel2_area, tel2_num };
    return (
        <CardBoxContainer className={cardClass}>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '10fr 1fr',
                    bgcolor: 'primary.light',
                    color: 'primary.contrastText',
                    fontWeight: 'bold',
                }}
                className={classnames({
                    inactive_item: isInactiveAdm,
                })}
            >
                <GenericBoxItem className="text-uppercase mr-0 pr-0">{admin.name}</GenericBoxItem>

                <GenericBoxItem className="ml-0 pl-0 text-right">
                    <LongMenu
                        options={[
                            {
                                label: 'Modificar',
                                onClick: () => history.push(`/administrator/edit/${admin.id}`, { admin }),
                            },
                            {
                                label: 'Eliminar',
                                dialogAlertTitle: 'Eliminar Administracion',
                                dialogAlertMessage: `Confirma eliminar administracion: ${admin.name}?`,
                                renderDialogAlert: ({ label, title, message }) => {
                                    return (
                                        <AlertDialogWrapper
                                            title={title}
                                            message={message}
                                            label={label}
                                            actionFunction={() =>
                                                remove({ id: String(admin.id) })(administratorState.dispatch)
                                            }
                                        />
                                    );
                                },
                            },
                        ]}
                    />
                </GenericBoxItem>
            </Box>
            <div
                className={classnames('card-body', {
                    inactive_item: isInactiveAdm,
                })}
            >
                {renderMissingItems(admin)}
                {admin.document && (
                    <div className="card-text">
                        <p className="document">
                            {getDocumentDescription(admin.documentId, documentState) +
                                ' ' +
                                formatDocument(admin.document)}
                        </p>
                    </div>
                )}

                <div className="card-text">
                    <ClientPhoneNumbers {...phones} />
                </div>
                <div className="card-text">
                    <ClientAddress address={admin.address} />
                </div>
                <div className="card-text">
                    <ExpandPanel title="Contacto">
                        {admin.contact && (
                            <p className="card-text">
                                <FontAwesomeIcon icon="id-card" /> {admin.contact}
                            </p>
                        )}

                        <p className="card-text text-lowercase">
                            {admin.email1 && (
                                <p>
                                    <FontAwesomeIcon icon="at" />{' '}
                                    <a target="_blank" rel="noopener noreferrer" href={'mailto:' + admin.email1}>
                                        {admin.email1}
                                    </a>
                                </p>
                            )}
                            {admin.email2 && (
                                <p>
                                    <FontAwesomeIcon icon="at" />{' '}
                                    <a target="_blank" rel="noopener noreferrer" href={'mailto:' + admin.email2}>
                                        {admin.email2}
                                    </a>
                                </p>
                            )}
                            {admin.web && (
                                <p>
                                    <a target="_blank" rel="noopener noreferrer" href={admin.web}>
                                        {admin.web}
                                    </a>
                                </p>
                            )}
                        </p>
                    </ExpandPanel>
                </div>
                <div className="card-text mt-3">
                    <ExpandPanel title="Consorcios">
                        <AdministratorConsorcios adminId={admin.id} />
                    </ExpandPanel>
                </div>

                {admin.comments && (
                    <div className="card-text mt-3">
                        <ExpandPanel title="Comentarios">
                            <div className="card-text" style={{ whiteSpace: 'break-spaces' }}>
                                {admin.comments}
                            </div>
                        </ExpandPanel>
                    </div>
                )}
            </div>
        </CardBoxContainer>
    );
};
