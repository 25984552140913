import React, { Fragment, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Box } from '@mui/material';

import { ClientSearchResult } from './components/client-search-result';
import ClientSearchCriteria from './components/client-search';
import { AppContext } from '../../store';
import Loader from '../../components/shared/loader';
import { filterClients, resetClientsFilter } from '../../domain/client/actions/filter';
import { ErrorNotification } from '../../components/shared/error';
import { PageTitle } from '../../components/shared/page-title';
import { ScrollToTop } from '../../components/shared/scroll-to-top';
import { Notification } from '../../components/shared/notification';
import { GenericBoxItem } from '../../components/shared/card-box-container';
import { Filters } from '../../components/shared/filters';

export const ClientSearchLayout = () => {
    const { client: clientState } = useContext(AppContext);
    const [clientFilterValue, setClientFilterValue] = useState('');
    const [shouldRender, setShouldRender] = useState(false);
    const history = useHistory();

    const handleFilterChange = (e: any) => {
        setClientFilterValue(e.target.value);
        filterClients({ text: e.target.value })(clientState.dispatch);
    };
    const resetFilters = () => {
        setClientFilterValue('');
        resetClientsFilter()(clientState.dispatch);
    };

    const renderSearchResult = () => {
        const areResults = Object.keys(clientState.searchResults).length > 0;
        if (!areResults) {
            return (
                <Notification
                    warn
                    title="No se encontraron resultados"
                    msg="No hay resultados que concuerden con el criterio de busqueda. Modifique el criterio de busqueda e intente nuevamente"
                />
            );
        }

        return (
            <Fragment>
                <Filters
                    filterValue={clientFilterValue}
                    handleFilterChange={handleFilterChange}
                    resetFilters={resetFilters}
                    setFilterValue={setClientFilterValue}
                    entityState={clientState}
                />

                {clientState.filterResults.map((id) => (
                    <ClientSearchResult key={id} client={clientState.searchResults[id]} />
                ))}
            </Fragment>
        );
    };

    const renderResults = () => {
        if (clientState.loading) return <Loader />;
        if (clientState.error) return <ErrorNotification error={clientState.error} />;

        const clientResultsQty = Object.keys(clientState.searchResults).length;

        if (clientResultsQty === 1) history.push(`/client/details/${Object.values(clientState.searchResults)[0].id}`);

        return (
            <>
                <h3>Resultados de Busqueda ({clientResultsQty})</h3>
                {renderSearchResult()}
            </>
        );
    };

    return (
        <>
            <PageTitle title="Cliente: Busqueda"></PageTitle>

            <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1fr, 2fr, 1fr)' }}>
                <GenericBoxItem>
                    <h3>Buscar Clientes</h3>
                </GenericBoxItem>

                <GenericBoxItem>
                    <ClientSearchCriteria afterSearch={() => setShouldRender(true)} />
                </GenericBoxItem>

                {shouldRender && <GenericBoxItem>{renderResults()}</GenericBoxItem>}
            </Box>
            <ScrollToTop />
        </>
    );
};
