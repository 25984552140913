import React, { useContext, Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Box, Button, MenuItem, Stack, StandardTextFieldProps, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { AppContext } from '../../../store';
import { PageTitle } from '../../../components/shared/page-title';
import { FormFieldBoxItem, getErrorText, hasError } from '../../../components/shared/form-utils';
import { Notification } from '../../../components/shared/notification';
import { GenericBoxItem } from '../../../components/shared/card-box-container';
import { isInactive } from '../../../utils/adm.utils';

export const AdministratorClientsSearch = ({ history }: RouteComponentProps) => {
    const { administrator: administratorState, ui: uiState } = useContext(AppContext);
    const formInitialValues = {
        admin: uiState.administrator.searchClients.adminId || '',
    };
    const hasAdministrators = Object.keys(administratorState.all).length > 0;

    const sortedElements = hasAdministrators
        ? Object.values(administratorState.all)
              .filter((el) => !isInactive(el))
              .sort((a, b) => (a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1))
        : [];
    const hasResultsToDisplay = sortedElements.length > 0;

    return (
        <Fragment>
            <PageTitle title={'Listado de Consorcios'}></PageTitle>

            <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1fr, 2fr, 1fr)' }}>
                <GenericBoxItem>
                    <h3>Listado de Consorcios</h3>
                </GenericBoxItem>

                <GenericBoxItem>
                    {!hasResultsToDisplay && (
                        <Notification warn title="Sin administraciones" msg="No hay admistraciones registradas o activas" />
                    )}
                    {hasResultsToDisplay && (
                        <Formik
                            initialValues={formInitialValues}
                            onSubmit={(values, { setSubmitting, setFieldValue }) => {
                                setSubmitting(false);
                                history.push(`/administrator/${values.admin}/clients`);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                resetForm,
                                setFieldValue,
                                /* and other goodies */
                            }) => {
                                const formikProps = (name: string): StandardTextFieldProps => ({
                                    variant: 'standard',
                                    name,
                                    //@ts-ignore
                                    value: values[name],
                                    error: hasError(touched, errors, name),
                                    helperText: getErrorText(errors, name),
                                    onChange: handleChange(name),
                                    onBlur: handleBlur(name),
                                    autoComplete: 'off',
                                });
                                return (
                                    <Form>
                                        <FormFieldBoxItem>
                                            <TextField select label="Administraci&oacute;n" {...formikProps('admin')}>
                                                {sortedElements.map((el, idx) => (
                                                    <MenuItem key={`admin_${idx + 1}`} value={el.id}>
                                                        {el.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormFieldBoxItem>

                                        <FormFieldBoxItem>
                                            <Stack direction="row" spacing={2}>
                                                <LoadingButton variant="contained" loading={isSubmitting} type="submit">
                                                    Buscar
                                                </LoadingButton>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => resetForm(formInitialValues)}
                                                    type="button"
                                                >
                                                    Reset
                                                </Button>
                                            </Stack>
                                        </FormFieldBoxItem>
                                    </Form>
                                );
                            }}
                        </Formik>
                    )}
                </GenericBoxItem>
            </Box>
        </Fragment>
    );
};
