import * as R from 'ramda';

import { AdministratorAction, AdministratorActionType, SearchClientsByAdministratorActionType } from '../actions/types';
import { filterAdministrators } from './utils';

export interface AdministratorState {
    all: Record<number, administrator.UIModel>;
    loading: boolean;
    error?: backend.common.ErrorResponse;
    dispatch?: any;
    filterResults: number[];
}
export default (state: AdministratorState, action: AdministratorAction): AdministratorState => {
    switch (action.type) {
        case AdministratorActionType.LOAD_SUCCESS: {
            const resultsObject = action.payload.reduce((acc: AdministratorState['all'], elem) => {
                acc[elem.id] = elem;
                return acc;
            }, {});
            return {
                ...state,
                all: resultsObject,
                filterResults: action.payload.sort(sortElementsByName).map((el) => el.id),
                loading: false,
                error: undefined,
            };
        }
        case AdministratorActionType.DELETE_SUCCESS: {
            const initialValue: AdministratorState['all'] = {};
            const temp = { ...state.all };
            const updatedElementsWithoutRemoved = Object.keys(temp)
                .filter((key) => key !== action.payload)
                .reduce((res, key) => {
                    res[Number(key)] = temp[Number(key)];
                    return res;
                }, initialValue);

            return {
                ...state,
                all: updatedElementsWithoutRemoved,
                filterResults: Object.values(updatedElementsWithoutRemoved)
                    .sort(sortElementsByName)
                    .map((el) => el.id),
                loading: false,
                error: undefined,
            };
        }
        case AdministratorActionType.LOAD_INIT:
        case AdministratorActionType.CREATE_INIT:
        case AdministratorActionType.EDIT_INIT:
        case SearchClientsByAdministratorActionType.LOAD_INIT:
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        // on purpose. This is handle via variable on alert dialog
        // so that the whole page does not re-render
        // case AdministratorActionType.DELETE_FAILURE:
        // case AdministratorActionType.DELETE_INIT:
        case AdministratorActionType.LOAD_FAILURE:
        case AdministratorActionType.CREATE_FAILURE:
        case AdministratorActionType.EDIT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case SearchClientsByAdministratorActionType.LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SearchClientsByAdministratorActionType.LOAD_SUCCESS: {
            let administratorToAddClients = state.all[action.payload.adminId];
            if (typeof administratorToAddClients === 'undefined') {
                //@ts-ignore
                administratorToAddClients = {
                    id: action.payload.adminId,
                    name: 'temp',
                    clients: action.payload.clients,
                };
                const newAll = state.all;
                newAll[action.payload.adminId] = administratorToAddClients;
                return {
                    ...state,
                    all: newAll,
                    loading: false,
                    error: undefined,
                };
            } else {
                administratorToAddClients.clients = action.payload.clients;
                return {
                    ...state,
                    loading: false,
                    error: undefined,
                };
            }
        }

        case AdministratorActionType.CREATE_SUCCESS:
        case AdministratorActionType.EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: undefined,
            };

        case AdministratorActionType.DO_FILTER: {
            const filteredClients = R.isEmpty(action.payload)
                ? state.all
                : filterAdministrators(action.payload, state.all);
            return {
                ...state,
                filterResults: Object.values(filteredClients)
                    .sort(sortElementsByName)
                    .map((el) => el.id),
            };
        }
        case AdministratorActionType.RESET_FILTER: {
            return {
                ...state,
                filterResults: Object.values(state.all)
                    .sort(sortElementsByName)
                    .map((el) => el.id),
                error: undefined,
            };
        }
        default:
            return { ...state };
    }
};

const sortElementsByName = (a: administrator.UIModel, b: administrator.UIModel) =>
    a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1;
