import { AdministratorActionType } from "./types";
import { FilterAdmRequest } from "./index.d";

const admFilter = (data: FilterAdmRequest) => ({
  type: AdministratorActionType.DO_FILTER,
  payload: data
});

const admFilterReset = () => ({
  type: AdministratorActionType.RESET_FILTER
});

export const filterAdministrators = (request: FilterAdmRequest) => (
  dispatch: React.Dispatch<any>
): void => dispatch(admFilter(request));

export const resetAdministratorsFilter = () => (dispatch: React.Dispatch<any>): void =>
  dispatch(admFilterReset());
