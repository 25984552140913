import React, { Fragment, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Box, Button } from '@mui/material';

import Loader from '../loader';

import { IndustryState } from '../../../domain/industry/reducer';
import { DocumentState } from '../../../domain/document/reducer';
import { IvaState } from '../../../domain/iva/reducer';
import { SellerState } from '../../../domain/seller/reducer';
import { ConfigGenericItem } from './config-generic-item';
import { ProductState } from '../../../domain/product/reducer';
import { filterProducts, resetProductsFilter } from '../../../domain/product/actions/filter';
import { PageTitle } from '../page-title';
import { ScrollToTop } from '../scroll-to-top';
import { GoBackButton } from '../go-back';
import { Notification } from '../notification';
import { GenericBoxItem } from '../card-box-container';
import { Filters } from '../../../components/shared/filters';

interface ConfigGenericContainerType extends RouteComponentProps {
    configItemSpecificState: IndustryState | IvaState | DocumentState | SellerState | ProductState;
}

const renderItems = (
    items: Array<industry.UIModel | iva.UIModel | documento.UIModel | seller.UIModel | product.UIModel>,
    history: RouteComponentProps['history']
) => {
    return items.map((el) => <ConfigGenericItem key={el.id} item={el} history={history} />);
};

function Container({ configItemSpecificState, history, location: { pathname } }: ConfigGenericContainerType) {
    const isProductsRelated = pathname === '/product';
    const [filterValue, setFilterValue] = useState('');
    const handleFilterChange = (e: any) => {
        setFilterValue(e.target.value);
        filterProducts({ text: e.target.value })(configItemSpecificState.dispatch);
    };
    const resetFilters = () => {
        setFilterValue('');
        resetProductsFilter()(configItemSpecificState.dispatch);
    };

    return (
        <Fragment>
            <PageTitle title="Listado de Elementos" />

            <Box sx={{ display: 'grid', gridTemplateRows: 'repeat(1fr, 2fr, 1fr)' }}>
                {configItemSpecificState.loading && (
                    <GenericBoxItem>
                        <Loader />
                    </GenericBoxItem>
                )}
                {!configItemSpecificState.loading && !configItemSpecificState && (
                    <GenericBoxItem>
                        <Notification warn title="Sin resultados" msg="No se encontraron resultados!" />
                    </GenericBoxItem>
                )}
                {!configItemSpecificState.loading && configItemSpecificState && (
                    <>
                        {isProductsRelated && (
                            <>
                                <GenericBoxItem>
                                    <Button
                                        // variant="contained"
                                        onClick={() => history.push('/product/create')}
                                    >
                                        Agregar nuevo producto
                                    </Button>
                                </GenericBoxItem>

                                <Filters
                                    filterValue={filterValue}
                                    handleFilterChange={handleFilterChange}
                                    resetFilters={resetFilters}
                                    setFilterValue={setFilterValue}
                                    entityState={configItemSpecificState as ProductState}
                                />

                                <GenericBoxItem>
                                    {(configItemSpecificState as ProductState).filterResults.map((id) => (
                                        <ConfigGenericItem
                                            key={`config-items-${id}`}
                                            item={
                                                (configItemSpecificState as ProductState).all.find(
                                                    (e) => e.id == id
                                                ) as product.UIModel
                                            }
                                            history={history}
                                        />
                                    ))}
                                </GenericBoxItem>
                            </>
                        )}

                        {!isProductsRelated && (
                            <GenericBoxItem>{renderItems(configItemSpecificState.all, history)}</GenericBoxItem>
                        )}
                    </>
                )}
                <GenericBoxItem>
                    <GoBackButton history={history} />
                </GenericBoxItem>
            </Box>
            <ScrollToTop />
        </Fragment>
    );
}

export default withRouter(Container);
