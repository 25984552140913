import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import classnames from 'classnames';
import { Alert, Box } from '@mui/material'

import { AppContext } from '../../../store';

import { ExtinguisherBrandsState } from '../../../domain/extinguisher/brand/reducer';
import { ExtinguisherCapacitiesState } from '../../../domain/extinguisher/capacity/reducer';
import { ExtinguisherTypesState } from '../../../domain/extinguisher/type/reducer';
import { getBrandDescription } from '../../../domain/extinguisher/brand/selectors';
import { getCapacityDescription } from '../../../domain/extinguisher/capacity/selectors';
import { getTypeDescription } from '../../../domain/extinguisher/type/selectors';
import { formatExpirationDate, formatManufacturerDate, isExpired, isManufactureDateExpired } from '../../../utils/extinguisher.utils';
import { LongMenu } from '../../../components/shared/menu-max-height';
import { remove as deleteExtinguisher } from '../../../domain/extinguisher/actions/delete';
import { AlertDialogWrapper } from '../../../components/shared/alert-dialog-wrapper';
import { CardBoxContainer, GenericBoxItem } from '../../../components/shared/card-box-container';

interface ExtinguisherSearchResult {
    extinguisher: extinguisher.UIModel,
    brandTypes: ExtinguisherBrandsState,
    capacityTypes: ExtinguisherCapacitiesState,
    typeTypes: ExtinguisherTypesState,
    onEdit: Function;
}
export const ExtinguisherSearchResult: React.FunctionComponent<ExtinguisherSearchResult> = ({
    extinguisher,
    brandTypes, capacityTypes, typeTypes,
    onEdit
}) => {
    const history = useHistory();

    const {
        status: clientStatus,
        extinguisher: extinguisherState
    } = useContext(AppContext);

    const cardClass = classnames({
        "border-danger": extinguisher.status === 2
    });
    const stateClass = classnames('text-right', {
        'text-danger': extinguisher.status === 2
    });
    const hasClient = !!extinguisher.clientId;

    const line1Text = `${getTypeDescription(extinguisher.type, typeTypes)} x ${getCapacityDescription(extinguisher.capacity, capacityTypes)} (${getBrandDescription(extinguisher.brand, brandTypes)})`;

    return (
        <CardBoxContainer className={cardClass}>
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: '11fr 1fr',
                bgcolor: "primary.light",
                color: "primary.contrastText",
                fontWeight: "bold"
            }}>
                <GenericBoxItem className="text-uppercase mr-0 pr-0" sx={{ margin: 'auto 0' }}>
                    Serie: {extinguisher.serialNumber}
                </GenericBoxItem>
                <GenericBoxItem className="ml-0 pl-0 text-right">
                    <LongMenu
                        options={[
                            {
                                label: "Modificar",
                                onClick: () => onEdit()
                            },
                            {
                                label: 'Ver Cliente',
                                onClick: () => history.push(`/client/details/${extinguisher.clientId}`),
                                disabled: !hasClient
                            },
                            {
                                label: 'Eliminar',
                                dialogAlertTitle: 'Eliminar Matafuego',
                                dialogAlertMessage: `Confirma eliminar matafuego serie: ${extinguisher.serialNumber}?`,
                                renderDialogAlert: ({ label, title, message }) => {
                                    return (<AlertDialogWrapper title={title} message={message} label={label}
                                        actionFunction={() => deleteExtinguisher({ serie: extinguisher.serialNumber })(extinguisherState.dispatch)}
                                    />)
                                }
                            }
                        ]} />
                </GenericBoxItem>
            </Box>

            <Box sx={{
                display: 'grid',
                gridTemplateRows: '1fr'
            }}>
                <GenericBoxItem className="text-uppercase">
                    {line1Text}
                </GenericBoxItem>
                <GenericBoxItem sx={{ mb: 0 }}>
                    Fecha Vencimiento: {formatExpirationDate(extinguisher)}
                    {isExpired(extinguisher) && <span style={{ color: 'red' }}> - Vencido!</span>}
                </GenericBoxItem>
                <GenericBoxItem sx={{ mb: 0 }}>
                    Fecha Fabricacion: {formatManufacturerDate(extinguisher)}
                    {isManufactureDateExpired(extinguisher) && <span style={{ color: 'red' }}> - Vencido!</span>}
                </GenericBoxItem>

                <GenericBoxItem className={stateClass}>
                    {clientStatus[extinguisher.status]}
                </GenericBoxItem>
                {!hasClient && <GenericBoxItem><Alert severity="warning">Extintor no asociado!</Alert></GenericBoxItem>}
            </Box>
        </CardBoxContainer>
    );
};