import { where, filter, curry, anyPass, compose } from "ramda";

import { AdministratorState } from ".";
import { FilterAdmRequest } from "../actions/index.d";

const getAddressObject = (
    admModel: administrator.UIModel
  ): backend.common.Address =>
  admModel.address;

export const filterAdministrators = (
    request: FilterAdmRequest,
    administrators: AdministratorState["all"]
  ) => {
    const containsInsensitive = curry(
      (a, b) =>
        typeof a !== "undefined" &&
        typeof b !== "undefined" &&
        String(b).toLowerCase().indexOf(String(a).toLowerCase()) > -1
    );
    const matchNameField = where({
      name: containsInsensitive(request.text)
    });
    const matchDocumentField = where({
        document: containsInsensitive(request.text)
    });
    const matchContactField = where({
      contact: containsInsensitive(request.text)
    });
    const _matchStreetField = where<object>({
      street: containsInsensitive(request.text)
    });
    const matchStreetField = (administrator: any): boolean =>
      compose(_matchStreetField, getAddressObject)(administrator);
  
    const allConditions = anyPass([
      matchNameField,
      matchDocumentField,
      matchContactField,
      matchStreetField
    ]);
  
    return filter(allConditions, administrators);
  };