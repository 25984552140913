import { FilterAdmRequest } from "./index.d";

export enum AdministratorActionType {
  LOAD_INIT = "@administrator/LOAD_INIT",
  LOAD_SUCCESS = "@administrator/LOAD_SUCCESS",
  LOAD_FAILURE = "@administrator/LOAD_FAILURE",

  CREATE_INIT = "@administrator/CREATE_INIT",
  CREATE_SUCCESS = "@administrator/CREATE_SUCCESS",
  CREATE_FAILURE = "@administrator/CREATE_FAILURE",

  EDIT_INIT = "@administrator/EDIT_INIT",
  EDIT_SUCCESS = "@administrator/EDIT_SUCCESS",
  EDIT_FAILURE = "@administrator/EDIT_FAILURE",

  DELETE_INIT = "@administrator/DELETE_INIT",
  DELETE_SUCCESS = "@administrator/DELETE_SUCCESS",
  DELETE_FAILURE = "@administrator/DELETE_FAILURE",

  DO_FILTER = "@administrator/DO_FILTER",
  RESET_FILTER = "@administrator/RESET_FILTER"
}

export enum SearchClientsByAdministratorActionType {
  LOAD_INIT = "@searchClientsByAdministrator/LOAD_INIT",
  LOAD_SUCCESS = "@searchClientsByAdministrator/LOAD_SUCCESS",
  LOAD_FAILURE = "@searchClientsByAdministrator/LOAD_FAILURE"
}

export type AdministratorAction =
  | { type: AdministratorActionType.LOAD_INIT }
  | {
    type: AdministratorActionType.LOAD_SUCCESS;
    payload: administrator.UIModel[];
  }
  | {
    type: AdministratorActionType.LOAD_FAILURE;
    payload: backend.common.ErrorResponse;
  }
  | { type: SearchClientsByAdministratorActionType.LOAD_INIT }
  | {
    type: SearchClientsByAdministratorActionType.LOAD_SUCCESS;
    payload: {
      adminId: number;
      clients: administrator.SearchClientsByAdministratorUIModel[];
    };
  }
  | {
    type: SearchClientsByAdministratorActionType.LOAD_FAILURE;
    payload: backend.common.ErrorResponse;
  }
  |
  { type: AdministratorActionType.CREATE_INIT }
  | {
    type: AdministratorActionType.CREATE_SUCCESS
  }
  | {
    type: AdministratorActionType.CREATE_FAILURE;
    payload: backend.common.ErrorResponse;
  }
  |
  { type: AdministratorActionType.EDIT_INIT }
  | {
    type: AdministratorActionType.EDIT_SUCCESS
  }
  | {
    type: AdministratorActionType.EDIT_FAILURE;
    payload: backend.common.ErrorResponse;
  }
  | { type: AdministratorActionType.DELETE_INIT }
  | {
    type: AdministratorActionType.DELETE_SUCCESS,
    payload: string
  }
  | {
    type: AdministratorActionType.DELETE_FAILURE,
    payload: backend.common.ErrorResponse
  } | { type: AdministratorActionType.DO_FILTER; payload: FilterAdmRequest }
| { type: AdministratorActionType.RESET_FILTER }