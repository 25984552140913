import React from 'react';
import { Box } from '@mui/material';

import { RouteComponentProps } from 'react-router-dom';
import { CardBoxContainer, GenericBoxItem } from '../card-box-container';
import { LongMenu } from '../menu-max-height';

type GenericItemType = iva.UIModel | seller.UIModel | documento.UIModel | industry.UIModel | product.UIModel;
interface ConfigGenericItemType {
    item: GenericItemType;
    history: RouteComponentProps['history'];
}

const renderProductOrOthers = (item: GenericItemType, history: RouteComponentProps['history']) => {
    if (item.hasOwnProperty('descShort')) {
        //TODO: TS instanceOf
        const productItem: product.UIModel = item as product.UIModel;

        return (
            <>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '10fr 1fr',
                        bgcolor: 'primary.light',
                        color: 'primary.contrastText',
                        fontWeight: 'bold',
                    }}
                >
                    <GenericBoxItem className="text-uppercase mr-0 pr-0">{productItem.descShort}</GenericBoxItem>
                    <GenericBoxItem className="ml-0 pl-0 text-right">
                        <LongMenu
                            options={[
                                {
                                    label: 'Modificar',
                                    onClick: () =>
                                        history.push(`/product/${productItem.id}/edit`, { product: productItem }),
                                },
                            ]}
                        />
                    </GenericBoxItem>
                </Box>
                <div className="card-body">{productItem.desc}</div>
            </>
        );
    } else {
        return <h5 className="card-header text-uppercase">{item.desc}</h5>;
    }
};
export const ConfigGenericItem: React.FunctionComponent<ConfigGenericItemType> = ({ item, history }) => {
    return <CardBoxContainer>{renderProductOrOthers(item, history)}</CardBoxContainer>;
};
