import { ProductActionType } from './types';
import { FilterRequest } from './index.d';

const filter = (data: FilterRequest) => ({
    type: ProductActionType.DO_FILTER,
    payload: data,
});

const resetFilter = () => ({
    type: ProductActionType.RESET_FILTER,
});

export const filterProducts =
    (request: FilterRequest) =>
    (dispatch: React.Dispatch<any>): void =>
        dispatch(filter(request));

export const resetProductsFilter =
    () =>
    (dispatch: React.Dispatch<any>): void =>
        dispatch(resetFilter());
