import React, { useContext, useState, useEffect, Fragment } from "react";
import { RouteComponentProps } from "react-router";

import classnames from "classnames";

import { AppContext } from "../../store";
import { ClientDetailsRouteParams } from "./types";
import { getClientDetails } from "../../domain/client/actions/getDetails";
import Loader from "../../components/shared/loader";

import { getSellerDescription } from "../../domain/seller/selectors";
import { getIndustryDescription } from "../../domain/industry/selectors";
import { getIvaDescription } from "../../domain/iva/selectors";
import { getDocumentDescription } from "../../domain/document/selectors";

import { ClientAddress } from "../client-search/components/client-address";
import { ClientPhoneNumbers } from "../client-search/components/client-phone-numbers";
import { ClientExtinguishersContainer } from "./extinguishers/extinguishers.container";

import { ExpandPanel } from "../../components/shared/expand-panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDocument, isInactive } from "../../utils/client.utils";
import { PageTitle } from "../../components/shared/page-title";
import { ScrollToTop } from "../../components/shared/scroll-to-top";
import { GoBackButton } from "../../components/shared/go-back";
import { PrintButton } from "../../components/shared/print-button";
import { Alert } from "@mui/material";
import { ClientHydrants } from "./clientHydrants";

export const ClientDetails = ({
  match,
  history
}: RouteComponentProps<ClientDetailsRouteParams>) => {
  const {
    client: clientState,
    seller: sellerState,
    industry: industryState,
    iva: ivaState,
    document: documentState
  } = useContext(AppContext);
  const [clientId] = useState(parseInt(match.params.id));

  const renderSecondaryAddress = () =>
    client.address2 && <ClientAddress address={client.address2} />;

  const renderDocument = () => {
    const hasDocument = client.document;
    return hasDocument ? (
      <div className="font-weight-bold">
        {getDocumentDescription(client.documentType, documentState) +
          " " +
          formatDocument(client.document)}
        <br />
        {getIvaDescription(client.iva, ivaState)}
      </div>
    ) : (
      <Alert severity="warning">No tiene documento registrado!</Alert>
    );
  };

  useEffect(() => {
    const client = clientState.searchResults[clientId] || undefined;
    if (typeof client === "undefined") {
      getClientDetails({ id: clientId })(clientState.dispatch);
    }
  }, [clientId]);

  const client = clientState.searchResults[clientId];
  if (typeof client === "undefined") return null;

  const { tel1_area, tel1_num, tel2_area, tel2_num } = client;
  const phones = { tel1_area, tel1_num, tel2_area, tel2_num };

  const isInactiveClient = isInactive(client);

  return (
    <Fragment>
      <section></section>
      <section>
        {clientState.loading && <Loader />}
        {!clientState.loading && !client && (
          <Fragment>
            <PageTitle title={"Cliente: No se encuentra"}></PageTitle>
            <Alert severity="error">Cliente no existente!</Alert>
          </Fragment>
        )}
        {!clientState.loading && client && (
          <Fragment>
            <PageTitle title={`[${client.id}] ${client.name}`}></PageTitle>
            <PrintButton />

            <div
              className={classnames("client-search-details-item card", {
                "border border-danger inactive_item": isInactiveClient
              })}
            >
              <h5 className="card-header text-uppercase">
                [{client.id}] {client.name}
                {client.fantasyName && (
                  <div className="fantasy">{client.fantasyName}</div>
                )}
                <div className="fantasy">
                  {getIndustryDescription(client.industry, industryState)}
                </div>
                {client.auditoria && <div className="text-warning">CON AUDITORIA</div>}
              </h5>

              <div className="card-body">
                <div className="card-text">{renderDocument()}</div>
                <div className="card-text">
                  <ClientPhoneNumbers {...phones} />
                </div>
                <div className="card-text">
                  <ClientAddress address={client.address1} />
                </div>

                <div className="card-text">{renderSecondaryAddress()}</div>

                <div className="card-text">
                  <ExpandPanel title="Contacto">
                    {client.contact && (
                      <p className="card-text">
                        <FontAwesomeIcon icon="id-card" /> {client.contact}
                      </p>
                    )}
                    {client.hours && (
                      <p className="card-text">
                        <FontAwesomeIcon icon="clock" /> {client.hours}
                      </p>
                    )}
                    {client.email && (
                      <p className="card-text text-lowercase">
                        <FontAwesomeIcon icon="at" />{" "}
                        <a target="_blank" rel="noopener noreferrer"
                          href={"mailto:" + client.email}>{client.email}</a>
                      </p>
                    )}
                    {client.web && (
                      <p className="card-text text-lowercase">
                        <FontAwesomeIcon icon="location-arrow" />{" "}
                        <a target="_blank" rel="noopener noreferrer"
                          href={client.web}>{client.web}</a>
                      </p>
                    )}
                    <p className="card-text">
                      Vendedor{" "}
                      {getSellerDescription(client.seller, sellerState)}
                    </p>
                    {client.comments && (
                      <p className="card-text">
                        <span className="font-weight-bold">Comentarios</span> <br />
                        <span style={{ whiteSpace: 'break-spaces' }}>{client.comments}</span>
                      </p>
                    )}
                  </ExpandPanel>
                </div>

                <div className="card-text">
                  <ClientHydrants quantity={client.hydrants_quantity} expirationDate={client.hydrants_expiration_date} />
                </div>

                <ClientExtinguishersContainer clientId={client.id} history={history} />

              </div>
            </div>
          </Fragment>
        )
        }
        {
          !clientState.loading && client && (<>
            <div style={{ padding: "1rem" }}>
              <button
                type="button"
                className="btn btn-primary btn-lg btn-block"
                onClick={() => history.push(`/client/${client.id}/edit`, { client })}
              >
                EDITAR
              </button>
            </div>
            <div style={{ padding: "1rem" }}>
              <button
                type="button"
                className="btn btn-secondary btn-lg btn-block"
                disabled={isInactiveClient}
                onClick={() => history.push(`/client/${client.id}/extinguishers/link`, { client: client })}
              >
                ASOCIAR EXTINTORES
              </button>
            </div>
            <div style={{ padding: "1rem" }}>
              <button
                type="button"
                className="btn btn-secondary btn-lg btn-block"
                disabled={isInactiveClient}
                onClick={() => history.push(`/client/${client.id}/quotes`)}
              >
                PRESUPUESTOS
              </button>
            </div>
          </>
          )
        }
      </section >
      <section>
        <GoBackButton history={history} />
      </section>
      <ScrollToTop />
    </Fragment >
  );
};
